import React from "react";
import { Date } from 'prismic-reactjs'
import Layout from "../components/layout";
import SEO from "../components/seo/seo-new"
import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { graphql } from 'gatsby';
import {
    ChartBarSquareIcon,
    ReceiptPercentIcon,
    CheckBadgeIcon
} from '@heroicons/react/24/outline'
import EcomNav from "../components/ecomNav"
import { StaticImage } from "gatsby-plugin-image";
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';



export const competitorQuery = graphql`
query MyQueryCompetitor ($competitor: String, $accountingPlatform: String, $ecommercePlatform: String, $ecomsUID: String, $language: String!){
 prismicCompetitors(data: {ecom: {elemMatch: {ecom_uid: {eq: $ecomsUID}}}, name: {eq: $competitor}}) {
        data {
            ecom {
                date_checked
                ecom_uid
                percent_off
                plan_1_competitor_price
                plan_1_order_amount
                plan_1_taxomate_price
                plan_2_competitor_privacy
                plan_2_order_amounts
                plan_2_taxomate_price
                plan_3_competitor_privacy
                plan_3_order_amount
                plan_3_taxomate_price
                source
                pricing_title
            }
            name
        }
        uid
    }
    allPrismicCompetitors(filter: {data: {ecom: {elemMatch: {ecom_uid: {eq: $ecomsUID}}}}}) {
            nodes {
                data {
                    name
                }
                uid
            }
        }

prismicEcommerce(data: {ecommerce_name: {text: {eq: $ecommercePlatform}}}) {
    uid
    data {
      status
      ecommerce_name {
        text
      }
      marketplaces {
        marketplace_name
        marketplace_uid
      }
      logo {
        alt
        url
      }
      hero_image {
        url
      }
      color
      sign_up_link {
        url
      }
      logo_white_svg {
        url
      }
       logo_white_height
       text_color
       accounting_integration1 {
        accounting_integration {
          document {
            ... on PrismicAccounting {
              data {
                short_name
                color
                icon {
                  url
                }
                 logo {
                  url
                }
                long_name
              }
              uid
            }
          }
        }
      }
    }
  }
  prismicAccounting(uid: {eq: $accountingPlatform}) {
    data {
      long_name
      short_name
      icon {
        alt
        url
      }
      logo {
        alt
        url
      }
    }
  }
   allPrismicEcommerce {
    nodes {
      data {
        marketplaces {
          marketplace_name
          marketplace_uid
        }
      }
    }
  }
  allPrismicTestimonial  (filter: {data: {ecom: {elemMatch: {ecommerce: {uid: {eq: $ecomsUID}}}}, ecommerce_featured: {eq: true}}}) {
    nodes {
      data {
        testimonial_text {
          html
        }
        seller_sales_amount
        review_location
        name
        ecom {
          ecommerce {
            uid
          }
        }
        ecommerce_featured
        avatar {
          url
        }
      }

    }
  }
  locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    }
`







// const sections = [
//     {
//         features: [
//             { name: 'Imports Settlements to QuickBooks', tiers: { 500: true, 1000: true, 5000: true } },
//             { name: 'Unlimited Marketplaces', tiers: { 500: true, 1000: true, 5000: true } },
//             { name: 'Maximum Available Settlement History', tiers: { 500: true, 1000: true, 5000: true } },
//             { name: 'FREE 1:1 Onboarding', tiers: { 500: true, 1000: true, 5000: true } },
//         ],
//     },

// ]


export default function Competitor({ data, pageContext }) {
    const lang = data.locales.edges[0].node.language

    const competitor = pageContext.competitor
    const competitorUID = pageContext.page
    const plans = data.prismicCompetitors.data.ecom
    const plan1 = plans.filter(ecom => ecom.ecom_uid === pageContext.ecomsUID);
    const plan = plan1[0]
    const date = Date(plan.date_checked);
    const { t } = useTranslation();


    const formattedDate = Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: '2-digit'
    }).format(date);


    const ecom = data.prismicEcommerce.data
    const ecomName = pageContext.ecommerceName
    const ecommercePlatform = pageContext.ecommercePlatform
    const ecomUID = pageContext.ecommerceUID
    const ecomsUID = pageContext.ecomsUID
    const ecomColor = ecom.color
    const register = ecom.sign_up_link.url
    const logoWhite = ecom.logo_white_svg.url
    const logoWhiteSize = ecom.logo_white_height
    const ecomTextColor = ecom.text_color
    const ecomStatus = ecom.status
    const accounting = ecom.accounting_integration1

    const amzMarketplace = ecom.ecommerce_name.text
    const allMarketplaces = data.allPrismicEcommerce.nodes
    const allPrismicCompetitors = data.allPrismicCompetitors.nodes
    const competitorCheck = allPrismicCompetitors[0]

    const features = [
        {
            name: t('Affordable') + ` ${ecommercePlatform} `+ t('Accounting'),
            description: `With plans starting at $14 taxomate is the cheapest ${ecommercePlatform} accounting integration on the market.`,
        },
        {
            name: t('No Marketplace & Seller Account Limits'),
            description: t("We don't believe on limiting features. Every plan comes with unlimited marketplace and seller accounts."),
        },
        {
            name: t('Cost of Goods Sold Tracking'),
            description: t('Cost of Goods (COGS) tracking comes with every account.'),
        },
        {
            name: t('Time Saver'),
            description: t('Simplify your accounting and bookkeeping by posting a single') + ` ${ecommercePlatform} ` + t('invoice to QuickBooks, Wave, or Xero instead of importing each order seperately.'),
        },
        {
            name: t('Inventory Valuation'),
            description: t('Get a clear picure of your') + ` ${ecommercePlatform} ` + t('inventory value.'),
        },
        {
            name: t('Import Maximum Available Sales History'),
            description: t('Import all available settlements in') + ` ${ecommercePlatform} ` + t('through the') + ` ${ecommercePlatform} ` + t('API.'),
        },
        {
            name: t('1:1 Onboarding & Support'),
            description: t('Get 1:1 onboarding and support via email, live chat, and 1:1 call. Completely free for all users.'),
        },
        {
            name: t('Bank-grade Security & Encryption'),
            description: t('Take comfort in knowing your data is encrypted and secured by the Microsoft Azure platform.'),
        }
    ]

    const originalPathname = `/${pageContext.page}/`

    let url;
    lang == "en" ?
        url = `/${competitorUID}/`
        :
        url = `/${lang}/${competitorUID}/`


    return (
        <Layout>
            <SEO
                title={t('Best') + ` ${competitor} ` + t('alternative for') + ` ${ecomName} `}
                description={t('Best') + ` ${competitor} ` + t('alternative for') + ` ${ecomName} ` + t('sellers. Most affordable') + ` ${competitor} ` + t('for') + ` ${ecomName} ` + t('alternatives for') + ` ${ecomName} ` + t('integration.') + ` ${competitor} ` + t('reviews,') + ` ${competitor} ` + t('coupon code.')}
                image="https://res.cloudinary.com/taxomate/image/upload/v1630007980/taxomate-social-card_tozy38.jpg"
                pathname={url}
                lang={lang}
                originalPathname={originalPathname}
                alternate
            />
            <EcomNav
                ecomUID={`${ecomUID}`}
                ecomName={`${ecomName}`}
                ecomColor={`${ecomColor}`}
                logoWhite={`${logoWhite}`}
                logoWhiteSize={`${logoWhiteSize}`}
                ecommercePlatform={`${ecommercePlatform}`}
                marketplace={`${amzMarketplace}`}
                allMarketplaces={allMarketplaces}
                accounting={accounting}
                allCompetitors={allPrismicCompetitors}
                ecomsUID={ecomsUID}
                competitorCheck={competitorCheck}
            />

            <section className="relative bg-gray-50 overflow-hidden lg:py-10">
                <div className="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full" aria-hidden="true">
                    <div className="relative h-full max-w-7xl mx-auto">
                        <svg
                            className="absolute right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2"
                            width={404}
                            height={784}
                            fill="none"
                            viewBox="0 0 404 784"
                        >
                            <defs>
                                <pattern
                                    id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                                    x={0}
                                    y={0}
                                    width={20}
                                    height={20}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width={404} height={784} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
                        </svg>
                        <svg
                            className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2"
                            width={404}
                            height={784}
                            fill="none"
                            viewBox="0 0 404 784"
                        >
                            <defs>
                                <pattern
                                    id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b"
                                    x={0}
                                    y={0}
                                    width={20}
                                    height={20}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width={404} height={784} fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)" />
                        </svg>
                    </div>
                </div>

                <div className="relative pt-6 pb-16 sm:pb-24">


                    <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24">
                        <div className="text-center">
                            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                                <span className="block xl:inline"><Trans>Looking for the</Trans> </span>{' '}
                                <span className="block text-blue-600 xl:inline"><Trans>Best</Trans> {competitor} <Trans>Alternative</Trans> </span>
                                <Trans>for</Trans> {ecommercePlatform}?
                            </h1>
                            <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                                <Trans>With savings of up to</Trans> {plan.percent_off}%+ <Trans>on monthly fees compared to</Trans> {competitor} <Trans>costs it is hard not to choose taxomate.</Trans>

                            </p>
                            <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
                                <div className="rounded-md shadow">
                                    <a
                                        href="https://app.taxomate.com/sign-up"
                                        className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                                    >
                                        <Trans>Get started</Trans>
                                    </a>
                                </div>
                                <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                                    <a
                                        href="#pricing-comparison"
                                        className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10"
                                    >
                                    <Trans>Learn More</Trans>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </main>

                </div>
            </section>






            <section id="pricing-comparison" class="relative py-20 2xl:py-40">
                <div class="container px-4 mx-auto">
                    <div class="mb-14 text-center">
                        <h2 class="mt-8 text-5xl text-slate-500 font-heading">{plan.pricing_title}</h2>
                    </div>
                    <div class="pt-10 overflow-x-auto overflow-y-hidden align-middle">
                        <table class="table-auto mx-auto mb-5 ">
                            <thead>
                                <tr class="text-lg text-center font-bold px-2 md:px-10 lg:px-20"><th class="pb-6 px-2 md:px-10 lg:px-20"></th>
                                    <th className="bg-green-400 text-white border-l-4 border-l-green-400 border-r-4 border-r-green-400 border-t-green-400 border-t-4 px-2 md:px-10 lg:px-20">TAXOMATE</th>
                                    <th className="bg-gray-200 text-gray-700 border-l-4 border-l-gray-200 border-r-4 border-r-gray-200 border-t-gray-200 border-t-4 px-2 md:px-10 lg:px-20">{competitor}    <sup>1</sup></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="text-xl text-center px-2 md:px-10 lg:px-20">
                                    <td className="py-5 lg:pr-6 pr-4 text-teal-600">{plan.plan_1_order_amount} <Trans>Orders / Month</Trans></td>
                                    <td className="bg-green-100 border-l-4 border-l-green-400 border-r-4 border-r-green-400 text-teal-600 px-2 md:px-10 lg:px-20">${plan.plan_1_taxomate_price} / month</td>
                                    <td className="bg-gray-50 border-l-4 border-l-gray-200 border-r-4 border-r-gray-200 text-teal-600 px-2 md:px-10 lg:px-20">${plan.plan_1_competitor_price} / month</td>

                                </tr>
                                <tr class="text-xl text-center px-2 md:px-10 lg:px-20">
                                    <td class="py-5 lg:pr-6 pr-4 text-teal-600">{plan.plan_2_order_amounts} <Trans>Orders / Month</Trans></td>
                                    <td className="bg-green-100 border-l-4 border-l-green-400 border-r-4 border-r-green-400 text-teal-600 px-2 md:px-10 lg:px-20">${plan.plan_2_taxomate_price} / month</td>
                                    <td className="bg-gray-50 border-l-4 border-l-gray-200 border-r-4 border-r-gray-200 text-teal-600 px-2 md:px-10 lg:px-20">${plan.plan_2_competitor_privacy} / month</td>

                                </tr>
                                <tr class="text-xl text-center px-2 md:px-10 lg:px-20">
                            <td class="py-5 lg:pr-6 pr-4 text-teal-600">{plan.plan_3_order_amount} <Trans>Orders / Month</Trans></td>
                                    <td className="bg-green-100 border-b-4 border-b-green-400 border-l-4 border-l-green-400 border-r-4 border-r-green-400 text-teal-600 px-2 md:px-10 lg:px-20">${plan.plan_3_taxomate_price} / month</td>
                                    <td className="bg-gray-50 border-b-4 border-b-gray-200 border-l-4 border-l-gray-200 border-r-4 border-r-gray-200 text-teal-600 px-2 md:px-10 lg:px-20">${plan.plan_3_competitor_privacy} / month</td>

                                </tr>
                            </tbody>
                        </table>
                        <div class="py-6">

                            <p class="text-lg text-gray-500 text-center"><sup>1</sup> <Trans>Source:</Trans> {plan.source} <Trans>as of</Trans> {formattedDate}</p>

                        </div>

                        <div className="py-10 mx-auto max-w-3xl">
                            <div className="p-2 border-2 border-slate-200">
                                <p class="text-lg text-gray-400 text-center"><Trans>Note: We are not affiliated, associated, authorized, endorsed by, or in any way officially connected with</Trans> {competitor}, <Trans>or any of its subsidiaries or its affiliate.</Trans></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section className="bg-blue-50 py-20">
                <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-10 lg:px-8">
                    <div className="max-w-3xl mx-auto text-center">
                        <h2 className="text-3xl font-extrabold text-gray-900"><Trans>Why choose taxomate?</Trans></h2>
                    </div>
                    <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
                        {features.map((feature) => (
                            <div key={feature.name} className="relative">
                                <dt>
                                    <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                                    <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                                </dt>
                                <dd className="mt-2 ml-9 text-base text-gray-500">{feature.description}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </section>











            <section className="bg-white">
                <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
                    <div className="bg-gradient-to-r from-feeling_moody_start to-feeling_moody_end rounded-3xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
                        <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
                            <div className="lg:self-center">
                                <h2 className="text-3xl font-extrabold text-gray-700 sm:text-4xl">
                                    <span className="block"><Trans>Ready to dive in?</Trans></span>
                                <span className="block"><Trans>Start your free trial today.</Trans></span>
                                </h2>
                                <p className="mt-4 text-lg leading-6 text-blue-600">
                                <Trans>Try taxomate free for 14 days.</Trans>
                                </p>
                                <a
                                    href="https://app.taxomate.com/sign-up"
                                    className="mt-8 bg-white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-indigo-600 hover:bg-indigo-50"
                                >
                                <Trans>Sign up for free</Trans>
                                </a>
                            </div>
                        </div>
                        <div className="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
                            <StaticImage
                                className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
                                src="../images/best-ecommerce-accounting-software.png"
                                alt="taxomate app screenshot"
                            />
                        </div>
                    </div>
                </div>
            </section>

        </Layout>
    );
}